import React from 'react';
import {request, request_file} from '../commonjs/request.js';
import { Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './descargas.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';
import Swal from 'sweetalert2';

class Descargas extends React.Component{

	constructor(props){
		super(props);

		this.state={
			aspirante :
			{
				folio : '',
				referencia : '',
				check1 : 1,  // 2 - LLenar solicitud(1), 					3 - Solicitud impresa(5)
				check2 : 1,  // 2 - Descargar referencia(2), 				3 - Esperando acreditar pago(3)
				check3 : 1,  // 2 - Pago acreditado puede descargar guia(4), 3 - Guia descargada (5)
				check4 : 1,  // 2 - Comprobante en revision     3 - Aceptado   4 - Rechazado
			},
			ficha :
			{
				id : '',
				folio : '',
				fecha_de_examen : '',
				hora_de_examen : '',
				aula_de_examen : '',
				liga_de_examen : '',
				clave_de_examen : '',
				examen_disponible : '',
			},
			mens :
			{
				chk1 : 'COMENZAR',
				chk2 : '',
				chk3 : '',
				chk4 : '',
			},
			redireccion : false,
			mensajeDeAvance : 'Revisa primero todas las ligas para descargar la referencia bancaria'
		}
		this.actualizaChecks = this.actualizaChecks.bind(this);
		this.revisarMensaje = this.revisarMensaje.bind(this);
		this.mensajeInicial = this.mensajeInicial.bind(this);
		this.actualizar = this.actualizar.bind(this);
	}

	componentWillMount()
	{
		// Swal.fire({
		// 	toast : true,
		// 	title: 'Cargando...',
		// 	showClass: { popup: 'animated fadeIn' },
		// 	hideClass: { popup: 'animated fadeOut' },
		// 	timer: 2000,
		// 	onBeforeOpen: () => { Swal.showLoading() },
		// })
		this.actualizaChecks();
		this.revisarFechaDeExamen();
		//this.mensajeInicial();
	}
	actualizaChecks()
	{
		var { aspirante } = this.state;

		aspirante.folio = this.props.folio;
		aspirante.referencia = this.props.referencia;
		aspirante.check1 = parseInt(this.props.check1,10);
		aspirante.check2 = parseInt(this.props.check2,10);
		aspirante.check3 = parseInt(this.props.check3,10);
		aspirante.check4 = parseInt(this.props.check4,10);

		this.setState({ aspirante : aspirante });
		this.revisarMensaje();
	}

	revisarFechaDeExamen()
	{
		var { aspirante, ficha } = this.state;
		let temp = this;

		request.get(`/FechaDeExamen/${aspirante.folio}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha=response.data;
					temp.setState({ ficha : ficha });
				}
				else
				{
					ficha.fecha_de_examen=null;
					temp.setState({ ficha : ficha });
				}
			}
		})
		.catch(error => {
		});

		this.setState({ ficha : ficha });
	}

	revisarMensaje()
	{
		var { aspirante, mens } = this.state;
		
		if (aspirante.check1 === 2) 
		{
			mens.chk1 = "";
			mens.chk2 = "Llena tu solicitud para descargar tu referencia";
			mens.chk4 = "Descarga tu solicitud para que te den tu liga para el examen";
		}
		if (aspirante.check2 === 2)
		{
			mens.chk2 = "";
			mens.chk3 = "Para descargar tu guia debes realizar tu pago en el banco";
		}
		if (aspirante.check2 === 3)
		{
			mens.chk2 = "";
			mens.chk3 = "Espera a que se acredite tu pago para descargar tu guia";
		}
		if (aspirante.check3 > 1)
		{
			mens.chk2 = "Tu pago fue acreditado";
			mens.chk3 = "";
		}

		this.setState({ mens : mens });	
	}

	mensajeInicial()
	{
		// MENSAJE INICIAL

		// Reunion informativa
	
		Swal.fire({
			title: '<strong>Reunión* informativa de carácter obligatorio</strong>',
			icon: 'info',
			html: '<b><b>Temas:</b></b><br>' + 
				  'Inicio de semestre<br>'+
				  'Inscripciones<br>'+
				  'Acta de acuerdo del proyecto de inversión<hr><b>'+
				  'Dia jueves 04 de julio.<br><br>'+
				  'Turno Matutino 8:00 hrs.<br>'+
				  'Turno Vespertino 18:00 hrs.</b><hr>',
			focusConfirm: false,
			confirmButtonText: 'Ok',
			footer: '<b>*La reunión es solo para padres de familia</b>',
		});
	}

	comenzarSolicitud=()=>
	{
		var { aspirante, mens } = this.state;
		let temp = this;

		aspirante.check1 = 2;
		mens.chk1 = "";
		mens.chk2 = "Llena tu solicitud para descargar tu referencia";

		request.post('/ActualizarRegistro',aspirante)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					aspirante=response.data;
					temp.setState({ aspirante : aspirante, mens : mens });
				}
			}
		})
		.catch(error => {
		});

		this.setState({ aspirante : aspirante, mens : mens });
	}

	llenarSolicitud=()=>
	{
		var {redireccion} = this.state;
		redireccion	= true;
		this.setState({ redireccion : redireccion });
	}

	decargarReferencia=()=>
	{
		var { aspirante } = this.state;

		// if(aspirante.referencia === null)
		// {
		// 	Swal.fire({
		// 		toast: true,
		// 		icon: 'warning',
		// 		title: 'Aún no se encuentra disponible tu referencia de pago',
		// 		showConfirmButton: false,
		// 		timer: 3000
		// 	});
		// }
		// else
		// {
			request_file()
			//.post('/referenciaform') // Para descargar formato de inscripción en blanco
			.post(`/referencia?folio=${aspirante.folio}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
				this.actualizaChecks();
			})
			.catch(error => {
				Swal.fire({
					toast : true,
					title: 'No se pudo generar tu referencia',
					showClass: { popup: 'animated fadeIn' },
					hideClass: { popup: 'animated fadeOut' },
					showConfirmButton: false,
					timer: 4000,
				})
				console.log(error);
			});
		//}
	}

	subirComprobante= async ()=>
	{
		var { aspirante } = this.state;
		let temp = this;

		try {
			const { value: archivo } = await Swal.fire({
												title: 'Adjuntar comprobante de pago',
												input: 'file',
												inputAttributes:
												{
													'accept': 'image/jpeg',
													'aria-label': 'Sube tu imagen'											}
											})
			if (archivo) 
			{
				const fileData = new FormData();
				fileData.append('folio',aspirante.folio);
				fileData.append('archivo',archivo);

				request.post('/cargarComprobante',fileData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								position: 'center-end',
								icon: 'success',
								title: 'Documento enviado',
								showConfirmButton: false,
								timer: 2500
							});
							aspirante.check4=2;
							temp.setState({  aspirante : aspirante });
						}
						else
						{
							Swal.fire({
								position: 'center-end',
								icon: 'error',
								title: 'El archivo no es válido',
								showConfirmButton: false,
								timer: 2500
							});
						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No pudo enviarse el archivo',
						showConfirmButton: false,
						timer: 1500
					});
				});


			}
			else
			{
				Swal.fire('No se selecciono ningun archivo');
			}
		} catch (e) {
			console.log('error:', e);
			return false;
		}

	}

	descargarGuia=()=>
	{
		Swal.fire({
			toast : true,
			title: 'Descargando...',
			showClass: { popup: 'animated fadeIn' },
			hideClass: { popup: 'animated fadeOut' },
			timer: 4000,
			onBeforeOpen: () => { Swal.showLoading() },
		})

		var {aspirante} = this.state;
		let temp = this;

		request_file()
		.post(`/descargar?archivo=GuiaDeEstudio`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"archivo.pdf");
			aspirante.check3=3;
			temp.setState({ aspirante : aspirante });

			this.actualizar();
		})
		.catch(error => {
			Swal.fire({
				toast : true,
				title: 'No fue posible descargar la guía de estudio',
				showClass: { popup: 'animated fadeIn' },
				hideClass: { popup: 'animated fadeOut' },
				showConfirmButton: false,
				timer: 20000,
			})
			console.log(error);
		});			
	}

	descargarGuiaParaPlataforma=()=>
	{
		Swal.fire({
			toast : true,
			title: 'Descargando...',
			showClass: { popup: 'animated fadeIn' },
			hideClass: { popup: 'animated fadeOut' },
			timer: 2000,
			onBeforeOpen: () => { Swal.showLoading() },
		})

		var {aspirante} = this.state;
		let temp = this;

		request_file()
		.post(`/descargar?archivo=GuiaParaPlataforma`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"archivo.pdf");
			aspirante.check3=3;
			temp.setState({ aspirante : aspirante });
			this.actualizar();
		})
		.catch(error => {
			console.log(error);
		});			
	}

	realizarExamen=()=>
	{
		// Swal.fire({
		// 	toast: true,
		// 	icon: 'warning',
		// 	title: 'Aún no se encuentra disponible el simulador',
		// 	showConfirmButton: false,
		// 	timer: 2500
		// });

		const url = 'http://www.evelapp.com/app';
		window.open(url, '_blank');
	}

	actualizar()
	{
		var {aspirante} = this.state;

		request.post('/ActualizarRegistro',aspirante)
		.then(function(response) { })
		.catch(error => { });
	}

	render(){

		let { aspirante, mens, redireccion } = this.state;

		//if(redireccion === true) return <Solicitud folio={aspirante.folio} />;
		if(redireccion === true) return <Redirect to={{ pathname:'/app/solicitud', state: { auto: aspirante.folio } }} />

		return (

			<div id="principal" className="principal container-fluid">
				<div className="row justify-content-center animated fadeIn">
					<div className="col-12 col-lg-11 col-xl-10">
			
					{/* TITULO */}
						<div className="pt-3 pb-3 w-100">
							<div className="card text-center sombra">
								<div className="card-header"></div>
								<div className="card-body">
									<p className="kau am textshadow lg">"Sistema de Ingreso 2023"&nbsp; del C.B.T.i.s.&nbsp;#41</p>
								</div>
								<div className="card-footer"></div>
							</div>
						</div>

						{/* 4 - EXAMEN DE ADMISION  */}

						{/*<div id="examen" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="pencil" aria-hidden="true"> EXAMEN DE ADMISION </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
										<br/>
										La segunda oportunidad del <b>Examen de selección para nuevo ingreso</b> se llevara a cabo este <b>VIERNES 25 DE JUNIO</b> 
										&nbsp;a las <b>2:00 P.M.</b>, y tendrás <b>tres horas y media</b> para resolverlo.<br/><br/>

										Para que puedas presentarlo sin ningún problema te recomendamos lo siguiente:<br/><br/>
										<b>-</b> Descarga tu ficha con fotografia, si no lo haces no se activará tu liga para el examen.<br/>
										<b>-</b> Resuelve tu examen en una computadora, y no en un dispositivo móvil.<br/>
										<b>-</b> Se puntual al iniciar el examen, solo tendras un intento y el tiempo esta limitado.<br/>
										<b>-</b> Utiliza el <b>simulador del examen</b> que estara disponible los dias <b>miercoles y jueves</b>,
										&nbsp;para que te des una idea de como sera tu examen.<br/>
										<b>-</b> Al menos un dia antes verifica que puedas entrar a la página donde se llevara a cabo el examen,
										para ello necesitas entrar con un usuario y una contraseña, los cuales seran tu número de ficha y tu 
										folio, si no recuerdas alguno, o no sabes cual es cual, aqui te lo decimos:<br/><br/>
										
										USUARIO: <b>{ficha.id}</b> <i>(Numero de ficha)</i><br/>
										CONTRASEÑA: <b>{aspirante.folio}</b> <i>(Folio)</i><br/><br/>
										
										Mas abajo te dejamos una guia para que la revises y te sirva de apoyo, si tienes
										alguna duda mandanos un mensaje de whatsapp.

										<br/><br/>

										<span className="am b u"> Ir a la plataforma del examen y del simulador.</span>
									
										<br/><br/>
									
									</p>
								</div>
								<div className="card-footer text-muted am b">
									COMENZAR EL EXAMEN &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
								</div>
								{
									aspirante.check1 === 3 ? 
										<button className="cuadro" onClick={this.realizarExamen}></button>
									:
										<button className="cuadro xl b verde notok2"> { mens.chk4 } </button>
								}
							</div>
						</div>*/}
										

					{/* INSTRUCCIONES PARA EXAMEN  */}
					{/*	<div id="examen" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="pencil" aria-hidden="true"> GUIA PARA INGRESO AL EXAMEN DE SELECCIÓN </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
										<br/>
										Esta guia te servira para que puedas entrar a la plataforma donde se llevara a cabo el examen.
										<br/>
										<br/>
									</p>
								</div>
								<div className="card-footer text-muted am b">
									DESCARGAR LA GUIA &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
								</div>
								{
									aspirante.check1 === 3 ? 
										<button className="cuadro" onClick={this.descargarGuiaParaPlataforma}></button>
									:
										<button className="cuadro xl b verde notok2"> { mens.chk4 } </button>
								}
							</div>
						</div>*/}

					{/* INSTRUCCIONES */}
						<div className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi h5" data-glyph="paperclip" aria-hidden="true"> INSTRUCCIONES: </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
										<br/>
										Si quieres obtener tu <b>ficha para presentar el examen de nuevo ingreso</b> en el C.B.T.I.S. #41, deberás realizar los siguientes pasos<br/><br/>
										<b>Primer Paso:</b><br/><br/>
										<span className="am"> Llena la solicitud</span> con los datos que se te indican,<br/>
										{/*debes  <span className="am">subir una fotografía</span> siguiendo las especificaciones,<br/>*/}
										y <span className="am"> confirma </span>que se hayan guardado.
										{/*<span className="am"> tus datos</span>.*/}

										<br/><br/><b>Segundo Paso:</b><br/><br/>
										Descarga tu 
										<span className="am"> referencia de pago</span>,<br/>
										acude al banco HSBC de tu preferencia a <span className="am"> realizar el pago</span>.<br/>
										Saca una copia a tu comprobante y 
										<span className="am"><b> llevalos al plantel.</b></span><br/>
										Es necesario que lo entregues en<br/>
										<span className="am"><b>la oficina de servicios financieros </b></span>
										en horario
										<span className="am"><b> de 9:30a.m. a 2:00p.m.;</b></span><br/>
										Una vez que tu pago sea validado
										<span className="am"><b> podrás imprimir tu ficha.</b></span>

										<br/><br/><b>Tercer Paso:</b><br/><br/>
										Una vez que tu pago se haya acreditado, <br/>
										podras utilizar el botón
										<span className="am"><b> "IMPRIMIR FICHA" </b></span>
										en la hoja donde capturaste tus datos; <br/>
										Ahí podras descargar tu ficha,<br/>
										en ella se indicará tu número de ficha, y el día, hora y aula donde presentarás tu examen.<br/>
										<span className="am"><b>No olvides llevar tu ficha impresa ese día.</b></span>

										<br/><br/><b>Cuarto Paso:</b><br/><br/>
										<span className="am">Descarga tu guia de estudio</span> para que te prepares para el examen.<br/>
										{/*ó acude al plantel con tu ficha impresa a partir del<br/>
										<span className="am">Martes 24 al Viernes 27 de Mayo de 10:00a.m. a 7:00p.m.</span><br/>
										para recoger tu guía de estudio impresa.
										<br/><br/><br/>
										<b>Los resultados del examen de selección se publicarán aquí, a partir del 4 de julio.</b>*/}
										<br/><br/><br/>
									</p>
								</div>
								<div className="card-footer text-muted am b">
									&nbsp;
								</div>
				
							</div>
						</div>

					{/* 1 - FORMATO DE INSCRIPCION */}
						<div id="solicitud" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="document" aria-hidden="true"> SOLICITUD DE FICHA PARA EXAMEN DE ADMISIÓN </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
										Deberás llenar este formato con tus datos, y una vez que tu pago sea acreditado podras descargarlo e imprimirlo.
									</p>
								</div>
								<div className="card-footer text-muted am b">
									COMPLETAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
								</div>
								{
									aspirante.check1 === 1 ? <button className="animated fadeIn cuadro xl b verde notok" onClick={this.comenzarSolicitud}> { mens.chk1 } </button> : ''
								}
								{
									aspirante.check1 === 2 ? <button className="cuadro" onClick={this.llenarSolicitud}> </button> : ''
								}
								{
									aspirante.check1 === 3 ? <button className="cuadro ok" onClick={this.llenarSolicitud}> </button> : ''
								}
							</div>
						</div>

					{/* 2 - REFERENCIA DE PAGO */}
						<div id="referencia" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> Referencia Bancaria </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
										Con esta hoja podrás acudir a tu banco HSBC mas cercano a cubrir tu cuota de cooperación voluntaria.<br/>
										Una vez que realices el pago, sacale una copia y entrega la original en la oficina de servicios financieros 
										de <b> de 9:30a.m. a 2:00p.m.</b> en el plantel.<br/>
										Debes esperar al menos un dia habil para que se acredite tu pago.
									</p>
								</div>
								<div className="card-footer text-muted am b">
									DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
								</div>
								{
									aspirante.check2 === 1 ? <button className="cuadro xl b verde notok">  { mens.chk2 } </button> : ''
								}
								{
									aspirante.check2 === 2 ? <button className="cuadro" onClick={this.decargarReferencia}> </button> : ''
								}
								{   
									aspirante.check2 === 3 ?
										aspirante.check3 > 1 ?
											<button className="cuadro xl b verde notok" onClick={this.decargarReferencia}> { mens.chk2 } </button>
										:
											<button className="cuadro ok" onClick={this.decargarReferencia}> </button>
									:
										""
								}
							</div>
						</div>

					{/* 5 - CARGA  DE COMPROBANTE */}

						{/*<div id="guia" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> COMPROBANTE DE PAGO </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
									<br/><br/>
										Sube aqui la foto de tu comprobante de pago y espera a que sea validado,
										una vez hecho esto podras descargar e imprimir tu ficha para el examen de selección.<br/>
										<br/><br/>
									</p>
								</div>
								{
									aspirante.check4 === 1 ?
										<div className="card-footer text-muted am b">
											CARGAR &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
										</div>
									:
										aspirante.check4 === 2 ?										
											<div className="card-footer text-muted am b">
												EN REVISION (CAMBIAR) &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
											</div>
										:
											aspirante.check4 === 3 ?	
												<div className="card-footer text-muted am b">
													AUTORIZADO &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
												</div>
											:
												<div className="card-footer text-muted am b">
													COMPROBANTE RECHAZADO (CAMBIAR) &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
												</div>
								}
								{
									aspirante.check2 === 1 ? <button className="cuadro xl b verde notok">  { mens.chk2 } </button> : ''
								}
								{
									aspirante.check2 === 3 ? 
										aspirante.check3 === 1 ?
											<button className="cuadro" onClick={this.subirComprobante}> </button> 
										:
											<button className="cuadro xl b verde notok">  { mens.chk2 } </button>											
									:
										""
								}
						
							</div>
						</div>*/}

					{/* 3 - GUIA DE ESTUDIO */}

						<div id="guia" className="pt-3 pb-3 w-100 animated fadeIn">
							<div className="card text-center sombra">
								<div className="card-header">
									<h5>
										<span className="oi" data-glyph="book" aria-hidden="true"> GUIA DE ESTUDIO </span>
									</h5>
								</div>
								<div className="card-body">
									<p className="card-text sm">
									<br/><br/>
										Esta guia de estudio te servira para prepararte para el examen de ingreso.<br/>
										El archivo es un algo grande, asi que quiza tarde un poco.	
										<br/><br/>
									</p>
								</div>
								<div className="card-footer text-muted am b">
									DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
								</div>
								{
									aspirante.check3 === 1 ? <button className="cuadro xl b verde notok"> { mens.chk3 } </button> : ''
								}
								{
									aspirante.check3 === 2 ? <button className="cuadro" onClick={this.descargarGuia}></button> : ''
								}
								{
									aspirante.check3 === 3 ? <button className="cuadro ok" onClick={this.descargarGuia}></button> : ''
								}
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default Descargas;