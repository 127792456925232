import React from 'react';
import { Redirect } from 'react-router-dom';
import WindowSizeListener from 'react-window-size-listener';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import '../../fonts/css/open-iconic.css';
import '../commoncss/fonts.css';
import '../commoncss/animate.min.css';
import './nav.css';
import Swal from 'sweetalert2';


class Nav extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			navmode :
			{
				modo : 0,
				id : 0,
				nombre : '',
				apepat : '',
				apemat : '',
			},
			ancho : 0,
			redireccion : false,
		}
	}

	componentWillMount()
	{
		var { navmode } = this.state;
		navmode.modo = this.props.modo;
		navmode.id = this.props.id;
		navmode.nombre = this.props.nombre;
		navmode.apepat = this.props.apepat;
		navmode.apemat = this.props.apemat;
		this.setState({ navmode : navmode });
	}

	salirDeSolicitud=()=>
	{
		var { redireccion } = this.state;
		Swal.fire({
			position: 'top-end',
			title: 'Quieres salir de la captura?',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#13A703',
			confirmButtonText: 'Si, quiero salir',
			cancelButtonText: 'No, quiero continuar la captura',
			showClass: {
				popup: 'animated fadeInDown faster'
			},
			hideClass: {
				popup: 'animated fadeOutUp faster'
			}
		}).then((result) => {
			if (result.value)
			{
				redireccion = true;
				this.setState({ redireccion : redireccion });
			}
		})
	}

	mostrarAyuda=()=>
	{
		// Swal.fire({
		// 	position: 'top',
		// 	icon: 'question',
		// 	title: 'Número de folio',
		// 	text: 'Escribe el numero de folio que se te asigno en tu escuela',

		// 	showClass: { popup: 'animated fadeInDown faster' },
		// 	hideClass: { popup: 'animated fadeOutUp faster' }
		// })

		Swal.fire({
			title: '¿Como obtener tu ficha para examen de ingreso?',
			html: '<b><b>Primer Paso:</b></b><br>' + 
				  'Llena los datos que se te piden en la solicitud y después guárdala.<br><br>'+
				  '<b><b>Segundo Paso:</b></b><br>' + 
				  'Descarga la referencia bancaria y acude a pagarla a tu banco mas cercano. '+
				  'Debes esperar al menos un día hábil para que tu pago sea acreditado.<br><br>'+
				  '<b><b>Tercer Paso:</b></b><br>' + 
				  'Una vez acreditado tu pago, descarga e imprime tu ficha.<br><br>'+
				  '<b><b>Cuarto Paso:</b></b><br>' + 
				  'Descarga tu guia de estudio o pasa al plantel a recoger una copia impresa presentando tu ficha.<br><br>'+
				  '<b><b>Listo!</b></b><br>' + 
				  'Ahora solo debes esperar la fecha para presentar tu examen.<br><hr>',
			confirmButtonText: 'Ok',
			footer: 'Si tienes alguna duda puedes comunicarte con nosotros por teléfono, whatsapp ó correo electrónico',
		
		});

	}

	render(){

		let { navmode, ancho, redireccion } = this.state;

		if(redireccion === true) return <Redirect to={{ pathname:'/app', state: { auto: navmode.id } }} />

		return (

			<div>

				<WindowSizeListener onResize={(windowSize) => this.setState({ancho : windowSize.windowWidth}) } />

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark navshadow">

					{/* MARCADORES DE TAMAÑO */}

					<span>
						<span className="oi mark mark1" data-glyph="media-record" aria-hidden="true"> </span>
						<span className="oi mark mark2" data-glyph="media-record" aria-hidden="true"> </span>
						<span className="oi mark mark3" data-glyph="media-record" aria-hidden="true"> </span>
						<span className="oi mark mark4" data-glyph="media-record" aria-hidden="true"> </span>
						<span className="oi mark mark5" data-glyph="media-record" aria-hidden="true"> </span>
					</span>

					{
						ancho > 767 ?
							<span className="nav-link capNav oi sm h-100 align-middle i" data-glyph="person" aria-hidden="true">
								&nbsp;&nbsp;{(navmode.nombre)} 
								<span className="capNav"> {navmode.apepat} </span>
								<span className="capNav"> {navmode.apemat} </span>
							</span>
						:
							<span className="nav-link capNav oi i sm h-100 align-middle" data-glyph="person" aria-hidden="true">
								&nbsp;{(navmode.nombre)}
							</span>
					}

					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
						<div className="navbar-nav der">

							{/* Opcion Ayuda De Descargas */}
							{
								navmode.modo === 1 ?
									<button className="nav-item nav-link margen nav circulo" onClick={this.mostrarAyuda}>
										<span className="oi" data-glyph="question-mark" aria-hidden="true"></span>&nbsp;&nbsp;AYUDA
									</button>
								:
									""
							}
							{/* Opcion Salir De Descargas */}
							{
								navmode.modo === 1 ?
									<a className="nav-item nav-link margen" href="/" >
										<span className="oi" data-glyph="power-standby" aria-hidden="true">&nbsp;&nbsp;SALIR</span>
									</a>
								:
									""
							}

							{/* Opcion Salir De Solicitud */}
							{
								navmode.modo === 2 ?
									<button className="nav-item margen nav-link nav" onClick={this.salirDeSolicitud}>
										<span className="oi" data-glyph="action-undo" aria-hidden="true">&nbsp;&nbsp;SALIR</span>
									</button>
								:
									""
							}
						</div>
					</div>
					
				</nav>

			</div>
		);
	}
}

export default Nav;