import React from 'react';
import {Row, Col} from 'reactstrap';
import {request} from '../commonjs/request.js';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './login.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';
//import Nav from '../nav/Nav.js';

class Login extends React.Component
{
	constructor(props){
		super(props);

		this.state={
			aspirante :
			{
				folio : '',
				apellido_paterno : '',
				fecha : '',
				bloqueado : '',
			},
			auto : 0,
			encontrado : 0,
			buscando : 0,
		}
	}

	componentDidMount()
	{

		// Swal.fire({			
		// 	html: '<br><br><b>Si te registraste el día 6 o 7 de junio...</b><br><br> '+
		// 		  '<b>Lleva tu comprobante de pago al plantel.</b><br><br> '+
		// 		  'Recuerda que necesitas realizar este paso para poder terminar tu proceso.<br><br>',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

		Swal.fire({			
			html: '<b><br><br>REVISA LOS RESULTADOS DEL EXAMEN DE SELECCIÓN EN:<br><br>'+
				  '<a href="http://cbtis41.net" >http://cbtis41.net</a><br>'+
				 
				  '<br>A PARTIR DEL 1 DE JULIO</b>',

			focusConfirm: true,
			confirmButtonText: 'Ok',
		});

		// Swal.fire({			
		// 	html: '<b><br><br>EL PROCESO DE SOLICITUD DE FICHAS HA CONCLUIDO</b><br><br><br>'+
		// 		  'visita<br>'+
		// 		  '<b><a href="http://fichas.cbtis41.net">http://fichas.cbtis41.net</a></b><br>'+
		// 		  '<br>a partir del <b>14 DE JUNIO</b> '+
		// 		  'para revisar la información acerca del examen de selección.',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

		// Swal.fire({			
		// 	html: '<br><br>El <b>VIERNES 18 de JUNIO</b> sera el <b>EXAMEN DE SELECCIÓN</b> para nuevo ingreso. '+
		// 		  '&nbsp; Entra al portal para que revises los pasos que debes seguir para presentarlo.'+
		// 		  '<br><br>Si te apuras tendras la oportunidad de hacer una prueba en el simulador.<br>',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });


		// Swal.fire({html: '<br><br>Si tu <b>REFERENCIA BANCARIA</b> se encuentra <b>VENCIDA</b>,<br>'+
		// 		  'ingresa al portal y <b>DESCARGALA NUEVAMENTE</b> para poder efectuar tu pago.<br>'+
		// 		  '<br><b>Solo tendras hasta el 17 de mayo antes de que cierre el banco para hacerlo.</b><br><br>',
		// 	focusConfirm: true,
		// 	confirmButtonText: 'Ok',
		// });

	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var {aspirante, encontrado} = this.state;

		aspirante[name] = value;
		encontrado = 0;
		this.setState({ aspirante : aspirante, encontrado : encontrado });
	}

	mueveFoto=()=>
	{
		request.get('/Mover')
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					console.log("OK");
				}
				else
				{
					console.log("X");
				}
			}
		})
		.catch(error => { });	
	}

	handleFormSubmit=(evt)=>
	{
		
		// CONSULTA DE FORMULARIO DE LOGIN
		evt.preventDefault();
		var { aspirante, encontrado, auto } = this.state;
		let temp = this;

		// CONSULTA RAPIDA DE MODULOS
		//request.get('/buscarsp/'+aspirante.folio)

		request.get('/buscar/'+aspirante.folio+'/'+aspirante.apellido_paterno+'/'+aspirante.fecha)
		.then(function(response)
		{	
			if(response.status === 200)
			{
				aspirante = response.data;
				if(aspirante.apellido_paterno != null)
				{
					aspirante.bloqueado = parseInt(aspirante.bloqueado,10);
					if(aspirante.bloqueado === 1)
					//if(aspirante.bloqueado === 3)
					{
						auto = parseInt(aspirante.folio,10);
						encontrado = 1;
						temp.setState({ auto : auto, encontrado : encontrado});
					}
					else
					{
						// Proceso de solicitud cerrado
						encontrado = 3;
						temp.setState({ encontrado : encontrado});
					}		
				}
				else
				{
					// No encontrado
					encontrado = 2;
					temp.setState({ encontrado : encontrado});
				}
			}
			//this.cargar(0);
		})
		.catch(error => { });	
	}

	ayuda=()=>
	{
		Swal.fire({
			position: 'top-end',
			icon: 'question',
			title: 'Número de folio',
			text: 'Escribe el numero de folio que se te asigno en el sitio de EducacionBC.edu.mx',
			showClass: { popup: 'animated fadeInDown faster' },
			hideClass: { popup: 'animated fadeOutUp faster' }
		})
	}

	cargando=()=>
	{
		Swal.fire({
			toast : true,
			title: 'Cargando...',
			showClass: { popup: 'animated fadeIn' },
			hideClass: { popup: 'animated fadeOut' },
		timer: 500,
			onBeforeOpen: () => { Swal.showLoading() },
		})
	}

	render(){

		let { aspirante, encontrado, auto, buscando } = this.state;

		if(encontrado===1) return <Redirect to={{ pathname:'/app', state: { auto : auto } }} />;

		return (

			<div id="principal" className="principal container-fluid">

				<div className="row mid pt-0 pt-lg-3 pb-5 justify-content-center">

					<div className="col-12 col-sm-11 col-md-10 col-lg-5 col-xl-6 pt-0 pt-md-2 pb-3">
						<div className="row h-100 justify-content-around">
							<div className="formTitulo col-11 col-sm-11 col-md-10 align-self-center sombra">
								<p className="kau bl tituloalign textshadow xxl">
									Solicitud De Fichas Para Nuevo Ingreso 2024 <br/> C.B.T.i.s.&nbsp;#41
								</p>
								<p className="lat tituloalign textshadow justificadomini sm am b">
									INGRESA A LA PÁGINA CON LOS DATOS QUE SE SOLICITAN.
								</p>
							</div>
						</div>
					</div>

					{ /* Ventana de login */ }

					<div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 pt-3 pb-3">
						<form className="container-fluid loginForm animated bounceInUp sombra" onSubmit={this.handleFormSubmit}>
							<Row className="pt-4">
								<Col xs={10}>
									<label> <span className="oi am" data-glyph="pencil" aria-hidden="true"></span>&nbsp;&nbsp;Número de Folio: </label>
								</Col>
								<Col xs={2} className="der">
									<button className="bk-am circulo" onClick={this.ayuda}>
										 <span className="oi negra" data-glyph="question-mark" aria-hidden="true"></span> 
									</button>
								</Col>
							</Row>
							<Row className="pb-4">
								<Col> <input className="form-control f" type="text" value={aspirante.folio} required name="folio" placeholder="####" onChange={this.handleInputChange} /> </Col>
							</Row>
						
							<Row>
								<Col> <label> <span className="oi am" data-glyph="person" aria-hidden="true"></span>&nbsp;&nbsp;Apellido Paterno: </label> </Col>
							</Row>
							<Row className="pb-4">
								<Col> <input className="form-control f" type="text" value={aspirante.apellido_paterno} required name="apellido_paterno" onChange={this.handleInputChange} /> </Col>
							</Row>
							<Row>
								<Col> <label> <span className="oi am" data-glyph="calendar" aria-hidden="true"></span>&nbsp;&nbsp;Fecha De Nacimiento: </label> </Col>
							</Row>
							<Row className="pb-4">
								<Col> <input className="form-control f" type="date" value={aspirante.fecha} required name="fecha" onChange={this.handleInputChange} /> </Col>
							</Row>
						
							<Row className="pt-2">
								<Col> <button className="btn btn-warning btn-block" type="submit"> <b> Ingresar </b> &nbsp; <span className="oi" data-glyph="account-login" aria-hidden="true"> </span> </button> </Col>
							</Row>
							{
							// <Row className="pt-2">
							// 	<Col> <button className="btn btn-warning btn-block" type="button" onClick={this.mueveFoto} > <b> Procesar </b> &nbsp; <span className="oi" data-glyph="account-login" aria-hidden="true"> </span> </button> </Col>
							// </Row>
							}
							<Row className="pt-3 pb-3">
								<Col>
									{
										encontrado === 2 ? 
											<div className="am"> <span className="oi" data-glyph="warning" aria-hidden="true"> </span> <font className="bl"> <i> No coinciden los datos </i> </font> </div>
										:
											encontrado === 3 ? 
												<div className="ro"> &nbsp;<span className="oi" data-glyph="ban" aria-hidden="true"> </span> <font className="bl b"> <i>&nbsp; El periodo de solicitudes esta cerrado. </i> </font> </div>
												//<div className="ro"> &nbsp;<span className="oi" data-glyph="ban" aria-hidden="true"> </span> <font className="bl b"> <i>&nbsp; No realizó solicitud de ficha. </i> </font> </div>
											:
												<div className="negra"> . </div>
									}
								</Col>
							</Row>
						</form>
						{ buscando === 1 ? this.cargando() : "" }
					</div>

					{ /* Fin ventana de login */ }

				</div>

				<div className="row justify-content-center">
					<div className="formayuda col-10 col-sm-8 col-md-7 col-lg-6 text-center pt-3 pb-1 sm bl">
						<font className="b"> ¿Necesitas ayuda?, comunicate con nosotros </font><br/><br/>
						<span className="oi am" data-glyph="envelope-closed" aria-hidden="true"></span>
						&nbsp;&nbsp;ingresocbtis41@gmail.com
						<br/>
						<span className="oi am" data-glyph="phone" aria-hidden="true"></span>
						&nbsp;&nbsp;&nbsp;(646)-176-61-76
						{/*<span className="am"> &nbsp;&nbsp;(Whatsapp)</span>*/}
						<br/>
						{/*<span className="oi am" data-glyph="clock" aria-hidden="true"></span>
						&nbsp;&nbsp;&nbsp;8:00 a.m. - 5:00 p.m.*/}
						<br/>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;