import React from 'react';
import {request, request_file} from '../commonjs/request.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './solicitud.css';
import '../commoncss/animate.min.css';
import '../commoncss/fonts.css';
import Swal from 'sweetalert2';
import ImgHombre from '../../images/hombre.svg';
import ImgMujer from '../../images/mujer.svg';
import instruccion1 from './../../images/instruccion1.jpg';
import instruccion2 from './../../images/instruccion2.jpg';
import instruccion3 from './../../images/instruccion3.jpg';

export default class Solicitud extends React.Component
{
	constructor(props)
	{
		super(props);

		this.state={
			ficha :
			{
				id : '',
				folio : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				fecha_de_nacimiento : '',
				sexo : '',
				edad : '',
				curp : '',
				secundaria : '',
				promedio : '',
				especialidad1 : '',
				especialidad2 : '',
				especialidad3 : '',
				correo : '',
				correo2 : '',
				correoOk : false,
				foto : '',
				estadoFoto : '', 	// 1 - Sin foto, 2 - En revision, 3 - Aprobada, 4 - Rechazada
				nombreFoto : '',
				imprimir : false,
			},
			archivo : null,
			especialidades :
			[
				{ id: '1', nombre: "Producción industrial de alimentos" },
				{ id: '2', nombre: "Programación" },
				{ id: '3', nombre: "CiberSeguridad" },
				{ id: '4', nombre: "Contabilidad" },
				{ id: '5', nombre: "Electrónica" },
				{ id: '6', nombre: "Laboratorista Clínico" },
				{ id: '7', nombre: "Mantenimiento automotriz" }
			],
			especialidades2 :
			[
				{ id: '2', nombre: "Programación" },
				{ id: '3', nombre: "CiberSeguridad" },
				{ id: '4', nombre: "Contabilidad" },
				{ id: '5', nombre: "Electrónica" },
				{ id: '7', nombre: "Mantenimiento automotriz" }
			],
			botonGuardarFoto : 1,
		}
	}

	componentWillMount()
	{
		// Swal.fire({
		// 	toast : true,
		// 	title: 'Cargando...',
		// 	showClass: { popup: 'animated fadeIn' },
		// 	hideClass: { popup: 'animated fadeOut' },
		// 	timer: 2000,
		// 	onBeforeOpen: () => { Swal.showLoading() },
		// })

		var { ficha } = this.state;
		let temp = this;

		ficha.folio = this.props.folio;

		request.get(`/Ficha/${ficha.folio}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha = response.data;
					if(parseInt(ficha.id,10) === 0)
					{
						ficha.estadoFoto = 1;
					}
					else
					{
						ficha.estadoFoto = parseInt(ficha.estadoFoto,10);
					}
					if(ficha.estadoFoto === 1)
					{
						if(ficha.sexo === "HOMBRE")
							ficha.foto = ImgHombre;
						else
							ficha.foto = ImgMujer;
					}
					if(typeof(ficha.correo) !== 'undefined')
					{
						ficha.correo2 = ficha.correo;
						ficha.correoOk = true;
					}
					ficha.id = parseInt(ficha.id,10);
					temp.setState({ ficha : ficha });
				}
			}
		})
		.catch(error => { });

		this.setState({ ficha : ficha });
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;
		var { ficha } = this.state;

		ficha[name] = value;

		this.setState({ ficha : ficha });
	}

	fileSelectedHandler=(event)=>
	{
		const target = event.target;
		const temp = target.files[0];

		var { ficha, archivo, botonGuardarFoto } = this.state;

		if(typeof(temp) !== 'undefined')
		{
			if( (temp.type === 'image/png') || (temp.type === 'image/jpg') || (temp.type === 'image/jpeg') || (temp.type === 'image/gif') )
			{
				if(temp.size < 1048576)
				{
					archivo = temp;
					ficha['foto'] = URL.createObjectURL(archivo);
					ficha['nombreFoto'] = archivo.name;
					botonGuardarFoto = 2;
					ficha.estadoFoto=1;
				}
				else
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'La imagen es muy grande',
						showConfirmButton: false,
						timer: 1500
					});
				}
			}
			else
			{
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: 'El tipo de archivo no es valido',
					showConfirmButton: false,
					timer: 1500
				});
			}
			
			this.setState({ ficha : ficha, archivo : archivo, botonGuardarFoto : botonGuardarFoto });
		}
	}

	// fileUpload=(event)=>
	// {
	// 	var { ficha, archivo, botonGuardarFoto } = this.state;
	// 	let temp = this;

	// 	Swal.fire({
	// 		toast : true,
	// 		title: 'Enviando foto...',
	// 		showClass: { popup: 'animated fadeIn' },
	// 		hideClass: { popup: 'animated fadeOut' },
	// 		timer: 2000,
	// 		onBeforeOpen: () => { Swal.showLoading() },
	// 	})

	// 	ficha['estadoFoto'] = 1;

	// 	const imageData = new FormData();
	// 	imageData.append('folio',ficha.folio);
	// 	imageData.append('archivo',archivo);

	// 	request.post('/CargarImagen',imageData)
	// 	.then(function(response)
	// 	{
	// 		if(response.status === 200)
	// 		{
	// 			if(response.data)
	// 			{
	// 				Swal.fire({
	// 					position: 'center-end',
	// 					icon: 'success',
	// 					title: 'Imagen enviada',
	// 					showConfirmButton: false,
	// 					timer: 2500
	// 				});

	// 				ficha['foto'] = response.data;
	// 				ficha['estadoFoto'] = 2;
	// 				ficha['imprimir'] = false;
	// 				botonGuardarFoto = 1;
					
	// 				temp.setState({ ficha : ficha, botonGuardarFoto : botonGuardarFoto });
	// 			}
	// 			else
	// 			{
	// 				Swal.fire({
	// 					position: 'center-end',
	// 					icon: 'error',
	// 					title: 'Hay un problema con la imagen',
	// 					showConfirmButton: false,
	// 					timer: 2500
	// 				});
	// 			}
	// 		}
	// 	})
	// 	.catch(error =>
	// 	{
	// 		Swal.fire({
	// 			position: 'top-end',
	// 			icon: 'error',
	// 			title: 'No se guardo la información.',
	// 			showConfirmButton: false,
	// 			timer: 1500
	// 		});
	// 	});

	// 	this.setState({ ficha : ficha, botonGuardarFoto : botonGuardarFoto });
	// }

	imprimir=()=>
	{
		Swal.fire({
			toast : true,
			title: 'Descargando...',
			showClass: { popup: 'animated fadeIn' },
			hideClass: { popup: 'animated fadeOut' },
			timer: 5000,
			onBeforeOpen: () => { Swal.showLoading() },
		})

		let { ficha } = this.state;

		request_file()
		.post(`/ImprimirSolicitud?numfolio=${ficha.folio}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	handleFormSubmit=(evt)=>
	{
		evt.preventDefault();
		var { ficha } = this.state;
		let temp = this;

		var error=1, mensaje="", arroba=0, servidorDeCorreo="";

		//ficha.edad = ficha.edad.trim();
		//ficha.secundaria = ficha.secundaria.trim();
		//ficha.promedio = ficha.promedio.trim();

		arroba=ficha.correo.indexOf("@");
		servidorDeCorreo=ficha.correo.substr(arroba,ficha.correo.length-arroba);
	
		if(ficha.secundaria.length>50)
		{
			mensaje="El nombre de la secundaria es muy largo";
		}
		else if(ficha.edad.length>2)
		{
			mensaje="La edad no es correcta";
		}
		else if((ficha.especialidad1 === ficha.especialidad2) || 
				(ficha.especialidad2 === ficha.especialidad3) ||
				(ficha.especialidad1 === ficha.especialidad3))
		{
			mensaje="No se deben repetir las especialidades";
		}
		else if(ficha.correo !== ficha.correo2)
		{
			mensaje="Las direcciones de correo no coinciden";
			ficha.correoOk = false;
		}
		else if(servidorDeCorreo.includes(".edu"))
		{
			mensaje="Debes escribir un correo personal, no institucional";
			ficha.correoOk = false;
		}
		else
		{
			error=0;
			ficha.correoOk = true;
		}
		
		if(error === 0)
		{
			request.post('/GuardarSolicitud',ficha)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
						ficha = response.data;
						Swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Los datos fueron guardados',
							showConfirmButton: false,
							timer: 1500
						});
						ficha.correo2 = ficha.correo;
						ficha.correoOk = true;
						temp.setState({ ficha : ficha });
					}
					else
					{
						Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: 'No se guardo la información.',
							showConfirmButton: false,
							timer: 1500
						});
					}
				}
			})
			.catch(error =>
			{
				Swal.fire({
					position: 'top-end',
					icon: 'error',
					title: 'No se guardo la información.',
					showConfirmButton: false,
					timer: 1500
				});
			});
		}
		else
		{
			Swal.fire({
				position: 'top-end',
				icon: 'error',
				title: mensaje,
				showConfirmButton: false,
				timer: 2500
			});
		}
	
		this.setState({ ficha : ficha });
	}

	subirFotografia = ()=>
	{
		
		// var { usuario } = this.state;
		// let temp = this;

		//Swal.fire({
		//	width: '600px',
		//	html: ' <img src="'+ instruccion1 +'" width="500" height="600">',
		//	footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
		//	confirmButtonText: 'Siguiente...'
		//}).then((result) => {
		//	if(result.value)
		//	{
				Swal.fire({
					width: '600px',
					html: ' <img src="'+ instruccion2 +'" width="500" height="600">',
					footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
					confirmButtonText: 'Siguiente...'
				}).then((result) => {
					if(result.value)
					{
						Swal.fire({
							width: '600px',
							html: ' <img src="'+ instruccion3 +'" width="500" height="600">',
							footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
							confirmButtonText: 'Entendido'
						}).then((result) => {
							if(result.value)
							{
								this.pedirfoto();
							}
						})
					}
				});
			//}
		//});
	}

	async pedirfoto()
	{
		var { ficha } = this.state;
		let temp = this;

		try {

			const { value: archivo } = await Swal.fire({
				title: 'Adjuntar Fotografia',
				input: 'file',
				inputAttributes:
				{
					'accept': 'image/*',
					'aria-label': 'Cargar fotografia'
				}
			})

			if (archivo) 
			{
				const fileData = new FormData();
				fileData.append('folio',ficha.folio);
				fileData.append('archivo',archivo);

				request.post('/CargarImagen',fileData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								position: 'center',
								icon: 'success',
								title: 'Tu fotografia ya fue enviada',
								text: 'Ahora puedes regresar al menu principal y descargar tu referencia de pago'
							});
							ficha.estadoFoto = 3;
							ficha.foto=response.data;
							temp.setState({  ficha : ficha });
						}
						else
						{
							Swal.fire({
								position: 'center-end',
								icon: 'error',
								title: 'El archivo no es válido',
								showConfirmButton: false,
								timer: 2500
							});

						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No pudo enviarse el archivo',
						showConfirmButton: false,
						timer: 1500
					});
					console.log(error);
				});
			}
			else
			{
				console.log(archivo);
				Swal.fire('No se selecciono ningun archivo');
			}

		} catch (e) {
			console.log('error:', e);
			return false;
		}
	}

	render()
	{
		let { ficha, especialidades, especialidades2 } = this.state;

		return (
			<div className="principal container-fluid">

				{/* BLOQUE DE INFORMACION */}
				<div className="row pb-4 justify-content-around">
					<div className="animated fadeIn text-center col-11 align-self-center pt-4 pt-lg-5 pb-4 formMensaje">
						<p className="kau am lg pb-2"> FICHA PARA EXAMEN DE ADMISIÓN</p>
						<p className="lat bl sm"> LLENA ESTA SOLICITUD CON TUS DATOS. </p>
						<p className="lat bl sm"> SI LA TERMINAS Y GUARDAS CORRECTAMENTE, PODRAS IMPRIMIRLA DESPUÉS. </p>
					</div>
				</div>
				<div className="col-12 pt-3 pt-lg-4 pb-3 pb-lg-4">
				</div>

				{/* FORMATO PARA CAPTURA */}
				<div className="row justify-content-center pb-5">
					<div className="col-12 col-sm-12 col-md-11">
						<form onSubmit={this.handleFormSubmit}>
							<div className="card text-center w-100">
								<div className="card-header der"> 
									<button className="btn btn-success mr-1 mr-sm-2 mr-lg-3" type="submit">
										<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
									</button>
									{
										parseInt(ficha.imprimir,10) === 1 ?
											<button type="button" className="btn btn-warning" onClick={this.imprimir}>
												<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
											</button> 
										:
											""
									}
 								</div>
								<div className="card-body container-fluid pb-3">
									<div className="row pt-3 pb-5">
										<h4 className="col-12 cen gr md"> DATOS DEL ALUMNO </h4>
									</div>

									<div className="row">
										<div className="col-8 col-sm-5 col-md-4 col-xl-3">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="folio">Folio</span>
												</div>
												<input type="text" className="form-control" name="ficha" value={ficha.folio} aria-label="Ficha" aria-describedby="ficha" disabled/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-8 col-sm-5 col-md-4 col-xl-3">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="ficha">No. de ficha</span>
												</div>
												<input type="text" className="form-control" name="ficha" value={parseInt(ficha.imprimir,10) === 1? ficha.id : "- - -"} aria-label="Ficha" aria-describedby="ficha" disabled/>
											</div>
										</div>
									</div>
									<div className="row justify-content-start">
										<div className="col-12 col-sm-12 col-md-12 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="nombre">Nombre</span>
												</div>
												<input type="text" className="form-control cap negra" value={ficha.nombre} aria-label="Nombre" aria-describedby="nombre" disabled/>
											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="apellido_paterno">Apellido paterno</span>
												</div>
												<input type="text" className="form-control cap negra" value={ficha.apellido_paterno} aria-label="Apellido_paterno" aria-describedby="apellido_paterno" disabled/>
											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="apellido_materno">Apellido materno</span>
												</div>
												<input type="text" className="form-control cap" value={ficha.apellido_materno} aria-label="Apellido_materno" aria-describedby="apellido_materno" disabled/>
											</div>
										</div>
									</div>
									<div className="row justify-content-start">
										<div className="col-12 col-sm-12 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="fecha_de_nacimiento">Fecha de nacimiento</span>
												</div>
												<input type="text" className="form-control" value={ficha.fecha_de_nacimiento} aria-label="Fecha_de_nacimiento" aria-describedby="fecha_de_nacimiento" disabled/>
											</div>
										</div>
										<div className="col-12 col-sm-7 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="sexo">Sexo</span>
												</div>
												<input type="text" className="form-control" value={ficha.sexo} aria-label="Sexo" aria-describedby="sexo" disabled/>
											</div>
										</div>
										<div className="col-12 col-sm-7 col-md-5 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="curp">CURP</span>
												</div>
												<input type="text" className="form-control" value={ficha.curp} aria-label="Curp" aria-describedby="curp" disabled/>
											</div>
										</div>
									</div>

									<div className="row justify-content-start">
										<div className="col-12 col-sm-12 col-md-12 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="secundaria">Secundaria</span>
												</div>
												<input type="text" className="form-control negra" name="secundaria" value={ficha.secundaria || ''} aria-label="Secundaria" aria-describedby="secundaria" required onChange={this.handleInputChange} />
											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="promedio">Promedio</span>
												</div>
												<input type="text" className="form-control cap negra" name="promedio" value={ficha.promedio} aria-label="promedio" aria-describedby="promedio" disabled/>
											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-6 col-xl-4">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="edad">Edad&nbsp;<span className="xs i">(años)</span></span>
												</div>
												<input type="text" className="form-control cap" name="edad" value={ficha.edad || ''} aria-label="edad" aria-describedby="edad" required onChange={this.handleInputChange} />
											</div>
										</div>
									</div>

									<div className="row pt-5 pb-5">
										<h4 className="col-12 cen gr md"> INFORMACIÓN DE SELECCIÓN </h4>
									</div>

									<div className="row justify-content-center">
										<div className="col-12 col-md-9 col-lg-8 col-xl-5">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<label className="input-group-text" htmlFor="especialidad1">Primera opción</label>
												</div>
												<select className="custom-select" name="especialidad1" value={ficha.especialidad1} id="especialidad1" required onChange={this.handleInputChange}>
													<option value="">Selecciona...</option>
													{
														especialidades.map( (esp) => <option key={esp.id} value={esp.id}> {esp.nombre} </option> )
													}
												</select>
											</div>
										</div>
									</div>
									<div className="row justify-content-center">
										<div className="col-12 col-md-9 col-lg-8 col-xl-5">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<label className="input-group-text" htmlFor="especialidad2">Segunda opción</label>
												</div>
												<select className="custom-select" name="especialidad2" value={ficha.especialidad2} id="especialidad2" required onChange={this.handleInputChange}>
													<option value="">Selecciona...</option>
													{
														especialidades2.map( (esp) => <option key={esp.id} value={esp.id}> {esp.nombre} </option> )
													}
												</select>
											</div>
										</div>
									</div>
									<div className="row justify-content-center">
										<div className="col-12 col-md-9 col-lg-8 col-xl-5">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<label className="input-group-text" htmlFor="especialidad3">Tercera opción</label>
												</div>
												<select className="custom-select" name="especialidad3" value={ficha.especialidad3} id="especialidad3" required onChange={this.handleInputChange}>
													<option value="">Selecciona...</option>
													{
														especialidades2.map( (esp) => <option key={esp.id} value={esp.id}> {esp.nombre} </option> )
													}
												</select>
											</div>
										</div>
									</div>

									<div className="row pt-5 pb-5">
										<h4 className="col-12 cen gr md"> INFORMACION DE CONTACTO </h4>
										<h5 className="col-12 cen gr"> Utiliza un correo electrónico personal, no uno institucional. </h5>
									</div>

									<div className="row justify-content-center">
										<div className="col-12 col-md-9 col-lg-8 col-xl-5">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="correo">Correo electrónico</span>
												</div>
												<input type="email" className="form-control negra" name="correo" value={ficha.correo || ''} aria-label="correo" aria-describedby="correo" required onChange={this.handleInputChange} />
												{
													ficha.correoOk ?
														<span className="verde">&nbsp;&nbsp;<span className="oi" data-glyph="check"></span></span>
													:
														<span className="ro">&nbsp;&nbsp;<span className="oi" data-glyph="x"></span></span>
												}
											</div>
										</div>
									</div>
									<div className="row justify-content-center">
										<div className="col-12 col-md-9 col-lg-8 col-xl-5">
											<div className="input-group input-group-sm mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="correo2">Confirma tu correo</span>
												</div>
												<input type="email" className="form-control negra" name="correo2" value={ficha.correo2 || ''} aria-label="correo2" aria-describedby="correo2" required onChange={this.handleInputChange} />
												{
													ficha.correoOk ?
														<span className="verde">&nbsp;&nbsp;<span className="oi" data-glyph="check"></span></span>
													:
														<span className="ro">&nbsp;&nbsp;<span className="oi" data-glyph="x"></span></span>
												}
											</div>
										</div>
									</div>

									{/*
										ficha.id > 0 ?
											<div className="row justify-content-center ">
												<div className="col-12 col-md-9 col-lg-8 col-xl-5 mb-3 pt-5">
													{
														ficha.estadoFoto === 1 ?
															<img className="rounded img-thumbnail marcoFoto" src={ficha.foto} alt="Tu foto" width="200" height="200"/>
														:
															<img className="rounded img-thumbnail marcoFoto" src={`data:image/jpeg;base64,${ficha.foto}`} alt="Tu foto" width="200" height="200"/>
													}
													<button type="button" className="botonFoto" disabled>
														{	ficha.estadoFoto === 3 ? <span className="oi md verde" data-glyph="check"> </span> : "" }
													</button>
												</div>
											</div>
										:
											""
									*/}

									{/*
										ficha.id > 0 ?
											<div className="row justify-content-center mb-4">
												<div className="col-12 col-md-9 col-lg-8 col-xl-5">
													<button type="button" className="btn btn-success" onClick={(e)=>this.subirFotografia()}>
														<b>Agregar una fotografía</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
													</button>
												</div>
											</div>
										:
											""
									*/}
{/*
									{
										ficha.id > 0 ?
											<div className="row justify-content-center">
												<div className="col-12 col-md-9 col-lg-8 col-xl-5">
													<div className="input-group input-group-sm mb-3">
														<div className="input-group-prepend">
															<span className="input-group-text" id="agregarFoto">Agrega una fotografia</span>
														</div>
														<div className="custom-file">
															<input type="file" className="custom-file-input" id="foto" aria-describedby="agregarFoto" onChange={this.fileSelectedHandler}/>
															<label className="custom-file-label izq" htmlFor="foto"> {ficha.nombreFoto} </label>
														</div>
													</div>
												</div>
											</div>
										:
											""
									}*/}

									{/*
										ficha.id > 0 ?
											<div>
												<div className="row pt-2 pb-3">
												{
													ficha.estadoFoto === 4 ?
														<font className="col-12 cen gr sm b rojo"> PARA QUE TU FICHA SEA VÁLIDA, <br/> ES NECESARIO QUE ENVIES UNA FOTOGRAFIA QUE CUMPLA LOS REQUISITOS </font>
													:
														<font className="col-12 cen gr sm b"> FOTOGRAFIAS INAPROPIADAS SON MOTIVO DE CANCELACIÓN DE PROCESO. </font>
												}
												</div>
											</div>
										:
											""
									*/}

								</div> {/* div cardbody - datos de captura */}
								
								{/* BOTON GUARDAR */}
								<div className="card-footer der">
									<button className="btn btn-success mr-1 mr-sm-2 mr-lg-3" type="submit">
										<b>Guardar</b> &ensp;<span className="oi" data-glyph="file" aria-hidden="true"> </span>
									</button>
									{
										parseInt(ficha.imprimir,10) === 1 ?
											<button type="button" className="btn btn-warning" onClick={this.imprimir}>
												<b>Imprimir Solicitud</b> &ensp;<span className="oi" data-glyph="print" aria-hidden="true"> </span>
											</button> 
										:
											""
									}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}

}