import React from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';

import Main from './Components/Main.js';
import Sub from './Components/Sub.js'

class App extends React.Component 
{
	 constructor(props){
		super(props);

		this.state={
			auto : 0,
		}
	}

	componentWillMount=() =>
	{

		var { auto } = this.state;

		if (this.props.location.state) 
			auto = this.props.location.state.auto ;
		else
			auto=0;

		this.setState({ auto : auto });
	}

	render()
	{
		let { auto } = this.state;

		if(auto===0)
		{
			return( <Redirect to={{pathname: '/'}} /> )
		}
		else
		{
			return( 
				<div className="fiil-window">
				<HashRouter>
					<Switch>
						<Route exact path="/app" name="Home" render={(props) => <Main auto={auto} /> } />
						<Route exact path="/app/solicitud" name="Solicitud" render={(props) => <Sub auto={auto} /> } />
					</Switch>
				</HashRouter>
				</div>
			)
		}
	}
}

export default App;