import React from 'react';
import {request} from './commonjs/request.js';
import NavigationBar from './nav/Nav.js';
import Descargas from './descargas/Descargas.js';
import PieDePagina from './footer/Footer.js';

class Main extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			navmode :
			{
				modo : 1,
				id : 0,
				nombre : '',
				apepat : '',
				apemat : '',
			},
			auto : 1,
			aspirante :
			{
				folio : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				referencia : '',
				check1 : 1,
				check2 : 1,  // 1 - Cerrado, 2 - Abierto, 3 - Realizado
				check3 : 1,
			},
		}
	}

	componentWillMount()
	{
		var { auto, navmode, aspirante } = this.state;
		let temp = this;

		auto = parseInt(this.props.auto,10);
		request.get('/buscarsp/'+auto) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				aspirante = response.data;
				if(aspirante.apellido_paterno != null)
				{
					navmode.modo = 1;
					navmode.id = aspirante.folio;
					navmode.nombre = aspirante.nombre;
					navmode.apepat = aspirante.apellido_paterno;
					navmode.apemat = aspirante.apellido_materno;

					temp.setState({ auto : auto, navmode : navmode, aspirante : aspirante });
				}
				else
				{

				}
			}
		})
		.catch(error => { });
		this.setState({ auto : auto, navmode : navmode, aspirante : aspirante });
	}

	render()
	{
		let { navmode, aspirante } = this.state;

		return (		

			<div id="login" className="Login">

				{/* MENU SUPERIOR  */}
				<div id="nav" className="nav">
				{
					navmode.nombre === "" ? "" : <NavigationBar {...navmode} />
				}
				</div>

				{/* BLOQUE DE CONTENIDO*/}
				<div id="content" className="content">
					{
						aspirante.nombre === "" ? "Cargando..." : <Descargas {...aspirante} />
					}
				</div>

				{/* PIE DE PAGINA */}
				<div id="footer" className="footer">
					<PieDePagina />
				</div>
	
			</div>
		);
	}
}

export default Main;