import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, HashRouter } from 'react-router-dom';

import Login from './Components/login/Login.js';
import App from './App';
//import Solicitudes from './Components/solicitud/Solicitud.js';
//import FueraDeServicio from './Components/fueradeservicio/Fueradeservicio.js';

ReactDOM.render(
	<HashRouter>
		<Switch>
			{
				// PARA USO NORMAL DEBEN ESTAR ACTIVADAS LAS 2 PRIMERAS OPCIONES
				// Y DESACTIVADAS LA SEGUNDA Y LA TERCERA
			}
			{
				<Route exact path="/" component={Login} />
			}
			{
				<Route path="/app" component={App} />
			}
			{
				//<Route exact path="/" component={Solicitudes} />
			}
			{
				//<Route exact path="/" component={FueraDeServicio} />
			}
		</Switch>
	</HashRouter>,
	document.getElementById('root')
);